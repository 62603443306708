@import "whitespace-reset";

$serif: 'IBM Plex Serif', serif;
$sans-serif: 'IBM Plex Sans', sans-serif;
$bold-sans-serif: 'IBM Plex Sans Bold', sans-serif;
$colors: ("text": #333, "heading": #1E3666, "gold": #CBAE4A, "gray": #a7a7a7);
$site_max_width: 1200px;

@function color($name) {
  @return map-get($colors, $name); }

@mixin  tablet {
  @media (min-width: 768px) {
    @content; } }

@mixin  desktop {
  @media (min-width: 1025px) {
    @content; } }

html,
body {
  height: 100%;
  font-size: 11px; }

body {
  padding: 2rem;
  font: 500 1rem/1.5 $sans-serif;
  color: color("text");
  @include tablet {
    &.default {
      display: grid;
      grid-template-rows: auto 1fr auto;
      min-height: 100%;
      box-sizing: border-box; } } }

a {
  color: color("heading");
  text-decoration: none; }

h1, h2, h3 {
  padding-bottom: 1.6rem;
  font: 500 3.2rem/132% $serif;
  color: color("heading"); }

h2 {
  font-size: 2.25rem;
  line-height: 120%; }

h3 {
  font-size: 1.75rem;
  line-height: 133%; }

p {
  padding-bottom: 2.4rem;
  font-size: 1.6rem;
  line-height: 150%; }

blockquote {
  padding: 1.6rem 0 4rem 3.4rem;
  font: 500 1.8rem/133% $serif;
  color: color("gray");
  p {
    padding: 0 0 0 3.4rem;
    font-size: inherit;
    border-left: 2px solid color("gold"); } }

nav {
  font: 700 1.2rem/1.6rem $bold-sans-serif;
  letter-spacing: .1rem;
  text-transform: uppercase;
  ul {
    padding-top: 2.4rem; }
  li {
    display: inline-block;
    + li {
      padding-left: 3rem; }
    &.active {
      a {
        color: color("gold"); } } } }

header[role="navigation"] {
  padding-bottom: 4rem;
  h1 {
    padding: 0;
    font: 700 1.6rem/132% $bold-sans-serif; }
  h2 {
    padding: 0;
    font: 500 1.4rem/129% $sans-serif;
    color: color("text"); }
  .colophon {
    &:before {
      display: inline-block;
      padding: 0 1rem 0 0; } }
  nav {
    margin-left: 4.5rem; }
  hgroup {
    margin-top: 2rem; }
  @include tablet {
    display: grid;
    grid-template: 8rem / 38% auto;
    grid-column-gap: 4rem;
    padding-bottom: 4rem;
    align-items: start;
    width: 100%;
    max-width: $site_max_width;
    margin: 0 auto;
    &:before {
      grid-column: 1 / span 2; }
    ul {
      position: relative;
      padding-top: 0;
      &:before {
        position: absolute;
        left: -1.6rem; } }
    nav {
      margin-top: 24px;
      margin-left: 0;
      padding-top: 2rem; } } }

footer {
  padding: 2.4rem 0 3.6rem 1.6rem;
  ul {
    padding-top: 0; }
  .colophon {
    position: relative;
    &:before {
      position: absolute;
      left: -1.6rem; } }
  li {
    width: 50%;
    padding-bottom: 2.5rem;
    vertical-align: top;
    + li {
      padding-left: 0; } }
  p {
    font-size: 1.4rem; }
  @include tablet {
    display: grid;
    grid-template-columns: 38% auto;
    grid-column-gap: 4rem;
    grid-template-areas: "social social" "copyright nav";
    align-self: end;
    width: 100%;
    max-width: $site_max_width;
    padding-left: 0;
    padding-bottom: 0;
    margin: 0 auto;
    ul {
      grid-area: social; }
    nav {
      grid-area: nav;
      li {
        width: auto;
        padding: 0;
        + li {
          padding-left: 2rem; } } }
    p {
      padding-bottom: 2rem;
      grid-area: copyright; } } }

.sr_only {
  display: none; }

.site_logo {
  float: left;
  width: 32px;
  img {
    width: 32px; } }
.site_name {
  margin-left: 4.5rem; }

#about {
  main {
    display: grid;
    grid-template-columns: 1fr; }
  picture {
    margin-bottom: 2.4rem;
    justify-self: center;
    img {
      width: 100%; } }
  article {
    font-size: 1.6rem;
    header {
      p {
        padding-bottom: 1rem; }
      h1 {
        padding-bottom: 2.4rem; } }
    ul, ol {
      padding: 0 0 2.4rem 2rem;
      list-style: disc; }
    ol {
      list-style: decimal; }
    a {
      text-decoration: underline;
      color: color("text"); } }
  @include tablet {
    main {
      grid-template-columns: 38% auto;
      grid-column-gap: 4rem;
      max-width: $site_max_width;
      margin: 0 auto; }
    picture {
      display: block;
      width: 100%;
      img {
        display: block;
        width: 80%;
        margin: 0 auto; } } } }

#blog_index {
  main {
    padding-bottom: 4rem;
    ul {
      padding-top: 1rem; }
    li {
      + li {
        padding-top: 2.4rem; }
      h2 {
        padding-bottom: .75rem; }
      p {
        padding: .5rem 0 0 0; } }
    p {
      font-size: 1.4rem; } }
  @include tablet {
    main {
      padding: 0 14%; } }
  @include desktop {
    main {
      display: grid;
      grid-template-columns: 38% auto;
      grid-column-gap: 4rem;
      max-width: $site_max_width;
      padding: 0;
      margin: 0 auto; } } }

#blog_index {
  @include tablet {
    grid-template-rows: auto auto 1fr auto; } }

#poster {
  display: block;
  margin: 0 -2rem;
  img {
    display: block;
    width: 100%;
    max-width: $site_max_width;
    margin: 0 auto 2.4rem auto; } }

#post {
  main {
    > picture {
      display: block;
      margin: 0 -2rem;
      img {
        display: block;
        width: 100%;
        max-width: $site_max_width;
        margin: 0 auto 2.4rem auto; } } }
  article {
    font-size: 1.6rem;
    header {
      p {
        padding-bottom: 1rem; }
      h1 {
        padding-bottom: 2.4rem; } }
    ul, ol {
      padding: 0 0 2.4rem 2rem;
      list-style: disc; }
    ol {
      list-style: decimal; }
    a {
      text-decoration: underline;
      color: color("text"); } }
  @include tablet {
    article {
      max-width: 1100px;
      padding: 0 14%;
      margin: 0 auto; } } }
