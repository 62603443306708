/*
----------------------------------------
Tantek Celik's Whitepsace Reset
     Author:  	Tantek Celik, Shane Riley
    Version:  	(CC) 2010 Some Rights Reserved - http://creativecommons.org/licenses/by/2.0
Description:	Resets default styling of common browsers to a common base
----------------------------------------
*/

ul,ol { list-style: none; }
h1,h2,h3,h4,h5,h6,pre,code { font-size: 1em; }
ul,ol,li,h1,h2,h3,h4,h5,h6,pre,form,body,html,p,blockquote,fieldset,input,dl,dt,dd, figure, figcaption {
  margin: 0;
  padding: 0; }
a img,:link img,:visited img, fieldset { border: none; }
address { font-style: normal; }
header, section, article, nav, footer, hgroup, details, summary, figure, main { display: block; }
mark {
  color: inherit;
  background: transparent; }
abbr { border: none; }
